import React  from "react";
import Charge_final from "./Charge_final"
import Charge_landing from "./Charge_Landing";

export default function Charge() {
  return (
    <div id="Charge">
      <Charge_landing/>
      <Charge_final/>
    </div>
  );
} 
